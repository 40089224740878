<template>
  <div ref="searchSchoolDom" :class="[isShowShortInfo && searchSchoolInfo.selectSchoolName ? 'show-short-info' : '']">
    <span class="short-btn" v-if="isShowShortInfo && this.searchSchoolInfo.selectSchoolName" @click="handleSeeSchollShort">简介</span>
    <el-select
      popper-class="search-school-popper"
      v-model="searchSchoolInfo.selectSchoolId"
      filterable
      remote
      clearable
      :placeholder="inputPlaceholder"
      :remote-method="remoteSchooolMethod"
      :loading="searchSchoolInfo.loading"
      @visible-change="handleSearchVisibleChange"
      @change="handleSearchSchoolSelectChange"
      :disabled="disableEdit"
      :popper-append-to-body="false"
    >
      <el-option
        v-for="item in searchSchoolInfo.schoolList"
        :key="item.id"
        :label="item.schoolName"
        :value="item.id"
        v-show="item.id"
      >
        <div class="select-school-item">
          <span class="select-school-item-name"
            >{{ item.schoolName }}
              <span v-show="item.schoolType == 1">(小学)</span>
              <span v-show="item.schoolType == 2">(中学)</span>
              <span v-show="item.schoolType == 3">(小学和中学)</span>
            </span
          >
          <span class="select-school-item-edit" @click.stop="handleEditSchoolInfo(item)"
            >添加简称</span
          >
        </div>
      </el-option>
      <div ref="searchSchoolBottom" class="search-end"></div>
      <div class="uploadTip" v-if="searchSchoolInfo.loadingMore">加载中...</div>
      <div class="uploadTip" v-if="searchSchoolInfo.noMoreData">没有更多数据</div>
    </el-select>
    <addSchoolKeywordDialog
      :addSchoolKeywordParams="addSchoolKeywordParams"
      @dialogDataChange="handleAddSchoolKeywordDataChange"
    ></addSchoolKeywordDialog>
    <seeSchoolShortDialog :seeSchoolShortParams="seeSchoolShortParams" @dialogDataChange="handleSeeSchoolShortDataChange"></seeSchoolShortDialog>
  </div>
</template>
<script>
import { apiGetSchoolList } from "@/api/sales";
import addSchoolKeywordDialog from "@/components/sales/addSchoolKeywordDialog";
import seeSchoolShortDialog from "@/components/sales/seeSchoolShortDialog";

export default {
  data() {
    return {
      searchSchoolInfo: {
        loading: false,
        _loading: false,
        loadingMore: false,
        noMoreData: false,
        schoolList: [],
        selectSchoolName: null,
        selectSchoolId: null,
        query: {
          pageNum: 1,
          pageSize: 10,
          keywords: "",
        },
      },
      observer: null,
      addSchoolKeywordParams: {
        type: "add",
        id: null,
        show: false,
      },
      seeSchoolShortParams: {
        show: false,
        schoolShort: null,
        schoolName: null,
      },
    };
  },
  props: {
    inputValue: {
      type: String,
    },
    inputPlaceholder: {
      type: String,
    },
    disableEdit: {
      type: Boolean,
    },
    isShowShortInfo: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    inputValue() {
      this.init();
    },
    disableEdit() {
      console.log("disableEdit: ", this.disableEdit);
    },
  },
  components: { addSchoolKeywordDialog, seeSchoolShortDialog },
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
    // 查看学校详情
    handleSeeSchollShort() {
      this.seeSchoolShortParams = {
        show: true,
        schoolShort: this.schoolShort,
        schoolName: this.searchSchoolInfo.selectSchoolName,
      }
    },
    // 查看学校弹框数据发生改变
    handleSeeSchoolShortDataChange({type}) {
      if(type == 'close'){
        this.seeSchoolShortParams.show = false 
      }
    },
    // 初始化数据
    init() {
      this.searchSchoolInfo.schoolList = [];
      this.searchSchoolInfo.selectSchoolId = null;
      this.searchSchoolInfo.selectSchoolName = null;
      this.searchSchoolInfo.query.keywords = null;
      if (this.inputValue) {
        console.log("this.inputValue: ", this.inputValue);
        this.searchSchoolInfo.selectSchoolId = 0;
        this.searchSchoolInfo.selectSchoolName = this.inputValue;
        this.searchSchoolInfo.query.keywords = this.inputValue;
        this.searchSchoolInfo.schoolList.push({
          schooleName: this.inputValue,
          id: 0,
        });
        this.$nextTick(() => {
          const dom = this.$refs.searchSchoolDom.querySelector(".el-input__inner");
          dom.value = this.inputValue;
        });
      }
    },
    // 创建观察器
    createObserver() {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (
              this.searchSchoolInfo.schoolList.length >=
              this.searchSchoolInfo.query.pageSize
            ) {
              this.getMoreSchoolList();
            }
          }
        });
      });
      this.observer.observe(this.$refs.searchSchoolBottom);
    },
    // 销毁观察者
    delObserver() {
      if (this.observer) {
        this.observer.unobserve(this.$refs.searchSchoolBottom);
      }
    },
    /* 搜索学校选择改变 */
    handleSearchSchoolSelectChange() {
      console.log("handleSearchSchoolSelectChange");
      let selectSchoolName = null, schoolShort = null;
      for (let i = 0; i < this.searchSchoolInfo.schoolList.length; i++) {
        if (
          this.searchSchoolInfo.selectSchoolId === this.searchSchoolInfo.schoolList[i].id
        ) {
          selectSchoolName = this.searchSchoolInfo.schoolList[i].schoolName;
          schoolShort = this.searchSchoolInfo.schoolList[i].schoolProfile
          break;
        }
      }
      this.searchSchoolInfo.selectSchoolName = selectSchoolName;
      this.searchSchoolInfo.query.keywords = selectSchoolName;
      this.schoolShort = schoolShort
      this.$emit("change", selectSchoolName);
    },
    /* 远程加载 */
    remoteSchooolMethod(query) {
      //console.log("开始加载");
      clearTimeout(this._searchTimer);
      this._searchTimer = setTimeout(() => {
        this.searchSchoolInfo.query.pageNum = 1;
        if (query) {
          this.searchSchoolInfo.query.keywords = query;
        }
        this.searchSchoolInfo.schoolList = [];
        if (this.inputValue) {
          this.searchSchoolInfo.schoolList.push({
            schoolName: this.inputValue,
            id: 0,
          });
        }
        this.searchSchoolInfo.noMoreData = false;
        this.getSchoolList();
      }, 500);
    },
    /* 编辑学校信息 */
    handleEditSchoolInfo(item) {
      console.log("handleEditSchoolInfo item: ", item);
      this.addSchoolKeywordParams = {
        show: true,
        id: item.id,
        type: "add",
      };
    },
    /* 编辑学校信息发生改变 */
    handleAddSchoolKeywordDataChange({ type }) {
      if (type == "close") {
        this.addSchoolKeywordParams.show = false;
      }
    },
    /* 加载学校 */
    async getSchoolList() {
      try {
        if (!this.searchSchoolInfo.loadingMore) {
          this.searchSchoolInfo.loading = true;
        }
        const { records, total } = await apiGetSchoolList(this.searchSchoolInfo.query);
        this.searchSchoolInfo.schoolList = [
          ...this.searchSchoolInfo.schoolList,
          ...records,
        ];
        if (
          this.searchSchoolInfo.query.pageNum * this.searchSchoolInfo.query.pageSize >=
          total
        ) {
          this.searchSchoolInfo.noMoreData = true;
        }
        this.searchSchoolInfo.loading = false;
        this.searchSchoolInfo.loadingMore = false;
      } catch (err) {
        let msg = err.msg ? err.msg : "查询学校发生异常！";
        this.msgError(msg);
        console.error("getSchoolList err: ", err);
        this.searchSchoolInfo.loading = false;
        this.searchSchoolInfo.loadingMore = false;
      }
    },
    /* 加载更多学校 */
    getMoreSchoolList() {
      if (!this.searchSchoolInfo.loadingMore && !this.searchSchoolInfo.noMoreData) {
        this.searchSchoolInfo.loadingMore = true;
        this.searchSchoolInfo.query.pageNum++;
        this.getSchoolList();
      }
    },
    /* 搜索学校下拉框显示隐藏 */
    handleSearchVisibleChange(visible) {
      console.log("visible: ", visible);
      if (visible) {
        clearTimeout(this._visibleTimer);
        this._visibleTimer = setTimeout(() => {
          const dom = this.$refs.searchSchoolDom.querySelector(".el-input__inner");
          const placeholder = dom.placeholder;
          // 先默认keywords
          this.searchSchoolInfo.query.keywords = this.inputValue;
          dom.value = this.inputValue;
          console.log("placeholder, inputValue: ", placeholder, this.inputValue);
          this.searchSchoolInfo.query.pageNum = 1;
          this.searchSchoolInfo.schoolList = [];
          if (this.inputValue) {
            this.searchSchoolInfo.schoolList.push({
              schoolName: this.inputValue,
              id: 0,
            });
          }
          this.searchSchoolInfo.noMoreData = false;
          this.getSchoolList();
          this.createObserver();
        }, 100);
      } else {
        if (!this.searchSchoolInfo.query.keywords) {
          console.log("空的学校！");
        } else if (
          this.searchSchoolInfo.query.keywords != this.searchSchoolInfo.selectSchoolName
        ) {
          console.log("保存失败！");
          //this.msgWarn("只有选择的学校才能保存成功！");
          //this.searchSchoolInfo.selectSchoolId = null
          //this.searchSchoolInfo.selectSchoolName = null
        } else {
          console.log(`保存学校成功：${this.searchSchoolInfo.selectSchoolName}`);
        }
        this.delObserver();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.show-short-info {
  position: relative;
  .short-btn {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 4px;
    right: 4px;
    color: #ffffff;
    font-size: 12px;
    background: #0194fb;
    border-radius: 3px;
    padding: 4px 6px;
  }
  /deep/ {
    .el-input__inner {
      padding-right: 56px;
    }
    .el-input__suffix {
      margin-right: 32px;
    }
  }
}
.select-school-item {
  width: 100%;
  .select-school-item-name {
    float: left;
    text-align: left;
  }
  .select-school-item-edit {
    margin-left: 20px;
    float: right;
    color: #409eff;
  }
}
.select-school-no-data {
  padding: 0 20px;
  cursor: default;
  color: #c0c4cc;
  text-align: center;
  &:hover {
    background: #ffffff;
  }
}
.search-end {
  height: 2px;
}
.uploadTip {
  line-height: 30px;
  color: #909399;
  text-align: center;
}
</style>
