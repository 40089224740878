<template>
  <el-dialog
    title="学校简介"
    :close-on-click-modal="false"
    :visible.sync="show"
    width="700px"
    center
    class="self-dialog"
    custom-class="doc-preview-dlalog"
    append-to-body
  >
    <div class="dialog-content" v-loading="loading">
      <div class="school-short" v-if="schoolShort" v-html="schoolShort"></div>
      <div class="no-data" v-else>暂无简介</div>
    </div>
  </el-dialog>
</template>

<script>
import { apiGetSchoolList } from "@/api/sales";
export default {
  data() {
    return {
      show: false,
      loading: false,
      schoolShort: "",
      schoolName: "",
    };
  },
  props: {
    seeSchoolShortParams: {
      type: Object,
      default: () => {
        return {
          show: false,
          schoolShort: null,
          schoolName: null,
        };
      },
    },
  },
  watch: {
    "seeSchoolShortParams.show"() {
      const { show, schoolShort, schoolName } = this.seeSchoolShortParams;
      console.log("schoolShort, schoolName: ", schoolShort, schoolName);
      this.show = show;
      if (schoolShort) {
        if (schoolShort.indexOf("<p>") == 0) {
          this.schoolShort = schoolShort;
        } else {
          this.schoolShort = schoolShort
            .replace(/\n/g, "</br>")
            .replace(/\s/g, "&nbsp;&nbsp;");
        }
      } else {
        this.schoolShort = "";
      }
      this.schoolName = schoolName;
    },
    show() {
      if (this.show) {
        if (!this.schoolShort) {
          this.getSchoolList();
        } else {
        }
      } else {
        this.$emit("dialogDataChange", { type: "close" });
      }
    },
  },
  mounted() {},

  methods: {
    /* 加载学校 */
    async getSchoolList() {
      try {
        this.loading = true;
        const { records } = await apiGetSchoolList({
          pageSize: 99,
          pageNum: 1,
          keywords: this.schoolName,
        });
        this.loading = false;
        records.forEach((item) => {
          if (item.schoolName == this.schoolName && item.schoolProfile) {
            if (item.schoolProfile.indexOf("<p>") == 0) {
              this.schoolShort = item.schoolProfile;
            } else {
              this.schoolShort = item.schoolProfile
                .replace(/\n/g, "</br>")
                .replace(/\s/g, "&nbsp;&nbsp;");
            }
          }
        });
      } catch (err) {
        let msg = err.msg ? err.msg : "获取学校简介发生异常！";
        this.msgError(msg);
        console.error("getSchoolList err: ", err);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ {
  .doc-preview-dlalog {
    .el-dialog__body {
      padding: 10px 20px 25px 25px !important;
    }
  }
}
.dialog-content {
  padding-right: 6px;
  height: calc(80vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  /deep/ {
    img {
      max-width: 100%;
    }
  }
  .no-data {
    line-height: 100px;
    text-align: center;
  }
}
</style>
